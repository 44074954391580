import { BusinessNode } from "@pdpp/lib-planta";

import { ClientBusinessNode, CostCentreAttribute } from "./types";

/**
 * - 2 => Country
 * - 5 => Unit
 * - 6 => BusinessFunction
 * - 7 => Department
 * - 8 => Activity
 */
const SUPPORTED_LEVELS = [5, 6, 7, 8];

// List comes from https://github.com/georgzoeller/iso-3166-1-alpha-2/blob/master/index.js#L37
const COUNTRIES = {
  AF: "Afghanistan",
  AX: "Åland Islands",
  AL: "Albania",
  DZ: "Algeria",
  AS: "American Samoa",
  AD: "Andorra",
  AO: "Angola",
  AI: "Anguilla",
  AQ: "Antarctica",
  AG: "Antigua and Barbuda",
  AR: "Argentina",
  AM: "Armenia",
  AW: "Aruba",
  AU: "Australia",
  AT: "Austria",
  AZ: "Azerbaijan",
  BS: "Bahamas",
  BH: "Bahrain",
  BD: "Bangladesh",
  BB: "Barbados",
  BY: "Belarus",
  BE: "Belgium",
  BZ: "Belize",
  BJ: "Benin",
  BM: "Bermuda",
  BT: "Bhutan",
  BO: "Bolivia, Plurinational State of",
  BQ: "Bonaire, Sint Eustatius and Saba",
  BA: "Bosnia and Herzegovina",
  BW: "Botswana",
  BV: "Bouvet Island",
  BR: "Brazil",
  IO: "British Indian Ocean Territory",
  BN: "Brunei Darussalam",
  BG: "Bulgaria",
  BF: "Burkina Faso",
  BI: "Burundi",
  KH: "Cambodia",
  CM: "Cameroon",
  CA: "Canada",
  CV: "Cape Verde",
  KY: "Cayman Islands",
  CF: "Central African Republic",
  TD: "Chad",
  CL: "Chile",
  CN: "China",
  CX: "Christmas Island",
  CC: "Cocos (Keeling) Islands",
  CO: "Colombia",
  KM: "Comoros",
  CG: "Congo",
  CD: "Congo, the Democratic Republic of the",
  CK: "Cook Islands",
  CR: "Costa Rica",
  CI: "Côte d'Ivoire",
  HR: "Croatia",
  CU: "Cuba",
  CW: "Curaçao",
  CY: "Cyprus",
  CZ: "Czech Republic",
  DK: "Denmark",
  DJ: "Djibouti",
  DM: "Dominica",
  DO: "Dominican Republic",
  EC: "Ecuador",
  EG: "Egypt",
  SV: "El Salvador",
  GQ: "Equatorial Guinea",
  ER: "Eritrea",
  EE: "Estonia",
  ET: "Ethiopia",
  FK: "Falkland Islands (Malvinas)",
  FO: "Faroe Islands",
  FJ: "Fiji",
  FI: "Finland",
  FR: "France",
  GF: "French Guiana",
  PF: "French Polynesia",
  TF: "French Southern Territories",
  GA: "Gabon",
  GM: "Gambia",
  GE: "Georgia",
  DE: "Germany",
  GH: "Ghana",
  GI: "Gibraltar",
  GR: "Greece",
  GL: "Greenland",
  GD: "Grenada",
  GP: "Guadeloupe",
  GU: "Guam",
  GT: "Guatemala",
  GG: "Guernsey",
  GN: "Guinea",
  GW: "Guinea-Bissau",
  GY: "Guyana",
  HT: "Haiti",
  HM: "Heard Island and McDonald Mcdonald Islands",
  VA: "Holy See (Vatican City State)",
  HN: "Honduras",
  HK: "Hong Kong",
  HU: "Hungary",
  IS: "Iceland",
  IN: "India",
  ID: "Indonesia",
  IR: "Iran, Islamic Republic of",
  IQ: "Iraq",
  IE: "Ireland",
  IM: "Isle of Man",
  IL: "Israel",
  IT: "Italy",
  JM: "Jamaica",
  JP: "Japan",
  JE: "Jersey",
  JO: "Jordan",
  KZ: "Kazakhstan",
  KE: "Kenya",
  KI: "Kiribati",
  XK: "Kosovo",
  KP: "Korea, Democratic People's Republic of",
  KR: "Korea, Republic of",
  KW: "Kuwait",
  KG: "Kyrgyzstan",
  LA: "Lao People's Democratic Republic",
  LV: "Latvia",
  LB: "Lebanon",
  LS: "Lesotho",
  LR: "Liberia",
  LY: "Libya",
  LI: "Liechtenstein",
  LT: "Lithuania",
  LU: "Luxembourg",
  MO: "Macao",
  MK: "North Macedonia",
  MG: "Madagascar",
  MW: "Malawi",
  MY: "Malaysia",
  MV: "Maldives",
  ML: "Mali",
  MT: "Malta",
  MH: "Marshall Islands",
  MQ: "Martinique",
  MR: "Mauritania",
  MU: "Mauritius",
  YT: "Mayotte",
  MX: "Mexico",
  FM: "Micronesia, Federated States of",
  MD: "Moldova, Republic of",
  MC: "Monaco",
  MN: "Mongolia",
  ME: "Montenegro",
  MS: "Montserrat",
  MA: "Morocco",
  MZ: "Mozambique",
  MM: "Myanmar",
  NA: "Namibia",
  NR: "Nauru",
  NP: "Nepal",
  NL: "Netherlands",
  AN: "Netherlands Antilles",
  NC: "New Caledonia",
  NZ: "New Zealand",
  NI: "Nicaragua",
  NE: "Niger",
  NG: "Nigeria",
  NU: "Niue",
  NF: "Norfolk Island",
  MP: "Northern Mariana Islands",
  NO: "Norway",
  OM: "Oman",
  PK: "Pakistan",
  PW: "Palau",
  PS: "Palestine, State of",
  PA: "Panama",
  PG: "Papua New Guinea",
  PY: "Paraguay",
  PE: "Peru",
  PH: "Philippines",
  PN: "Pitcairn",
  PL: "Poland",
  PT: "Portugal",
  PR: "Puerto Rico",
  QA: "Qatar",
  RE: "Réunion",
  RO: "Romania",
  RU: "Russian Federation",
  RW: "Rwanda",
  BL: "Saint Barthélemy",
  SH: "Saint Helena, Ascension and Tristan da Cunha",
  KN: "Saint Kitts and Nevis",
  LC: "Saint Lucia",
  MF: "Saint Martin (French part)",
  PM: "Saint Pierre and Miquelon",
  VC: "Saint Vincent and the Grenadines",
  WS: "Samoa",
  SM: "San Marino",
  ST: "Sao Tome and Principe",
  SA: "Saudi Arabia",
  SN: "Senegal",
  RS: "Serbia",
  SC: "Seychelles",
  SL: "Sierra Leone",
  SG: "Singapore",
  SX: "Sint Maarten (Dutch part)",
  SK: "Slovakia",
  SI: "Slovenia",
  SB: "Solomon Islands",
  SO: "Somalia",
  ZA: "South Africa",
  GS: "South Georgia and the South Sandwich Islands",
  SS: "South Sudan",
  ES: "Spain",
  LK: "Sri Lanka",
  SD: "Sudan",
  SR: "Suriname",
  SJ: "Svalbard and Jan Mayen",
  SZ: "Swaziland",
  SE: "Sweden",
  CH: "Switzerland",
  SY: "Syrian Arab Republic",
  TW: "Taiwan, Province of China",
  TJ: "Tajikistan",
  TZ: "Tanzania, United Republic of",
  TH: "Thailand",
  TL: "Timor-Leste",
  TG: "Togo",
  TK: "Tokelau",
  TO: "Tonga",
  TT: "Trinidad and Tobago",
  TN: "Tunisia",
  TR: "Turkey",
  TM: "Turkmenistan",
  TC: "Turks and Caicos Islands",
  TV: "Tuvalu",
  UG: "Uganda",
  UA: "Ukraine",
  AE: "United Arab Emirates",
  GB: "United Kingdom",
  US: "United States",
  UM: "United States Minor Outlying Islands",
  UY: "Uruguay",
  UZ: "Uzbekistan",
  VU: "Vanuatu",
  VE: "Venezuela, Bolivarian Republic of",
  VN: "Viet Nam",
  VG: "Virgin Islands, British",
  VI: "Virgin Islands, U.S.",
  WF: "Wallis and Futuna",
  EH: "Western Sahara",
  YE: "Yemen",
  ZM: "Zambia",
  ZW: "Zimbabwe",
} as const;

export const CODE_BY_COUNTRY_NAME = new Map<string, string>();

for (const [key, value] of Object.entries(COUNTRIES)) {
  CODE_BY_COUNTRY_NAME.set(value, key);
}

/**
 * Transform orgNodes to type used within a Client
 * and creates `country` nodes out of Unit attributes.
 */
export function getNormalisedOrgNodes(
  nodes: ReadonlyArray<BusinessNode>,
): ReadonlyArray<ClientBusinessNode> {
  const returnData: Array<ClientBusinessNode> = [];
  const insertedCountyNodes: Set<string> = new Set();

  for (const node of nodes) {
    if (SUPPORTED_LEVELS.includes(node.level)) {
      const id = node.id.toString();
      let parentId = node.parent as string | undefined;
      let type: ClientBusinessNode["type"] = "activity";

      switch (node.level) {
        case 5:
          type = "unit";
          // Create a country node out of the attribute of Unit
          if (node.attributes && "country" in node.attributes) {
            parentId = node.attributes["country"] as string;
            if (!insertedCountyNodes.has(parentId)) {
              returnData.push({
                id: parentId,
                parentId: undefined,
                publicId: "",
                name: parentId,
                type: "country",
              });
              insertedCountyNodes.add(parentId);
            }
          }
          break;
        case 6:
          type = "division";
          break;
        case 7:
          type = "department";
          node.name = node.name + ` (${node.publicId})`;
          break;
        default:
          type = "activity";
      }

      returnData.push({
        id,
        parentId,
        publicId: node.publicId.toString(),
        name: node.name,
        description: node.description,
        attributes: node.attributes as Record<string, CostCentreAttribute[]>,
        type,
      });
    }
  }

  return returnData;
}

const ancestorsCache = new Map<string, ReadonlyArray<ClientBusinessNode>>();

export function getAncestors(
  nodes: ReadonlyArray<ClientBusinessNode>,
  orgId: string | undefined,
): ReadonlyArray<ClientBusinessNode> {
  if (orgId === undefined) {
    return [];
  }

  if (ancestorsCache.has(orgId)) {
    return ancestorsCache.get(orgId) ?? [];
  }

  const node = nodes.find((n) => n.id === orgId);

  if (node === undefined) {
    return [];
  }

  if (node.parentId === undefined) {
    ancestorsCache.set(orgId, [node]);
    return [node];
  }

  const ancestors = [...getAncestors(nodes, node.parentId), node];
  ancestorsCache.set(orgId, ancestors);
  return ancestors;
}

export function getChildren(
  nodes: ReadonlyArray<ClientBusinessNode>,
  orgId: string | undefined,
): ReadonlyArray<ClientBusinessNode> {
  const node = nodes.find((n) => n.id === orgId);

  if (node === undefined) {
    return [];
  }

  return nodes.filter((n) => n.parentId === node.id);
}

export function getActivityNameById(
  nodes: ReadonlyArray<ClientBusinessNode>,
  id: string,
): string {
  const node = nodes.find((it) => it.id === id);

  if (node === undefined) {
    return id;
  }

  return node.name;
}

export const getOrgParent = (
  orgNodes: readonly ClientBusinessNode[],
  orgParentId: string | undefined,
): ClientBusinessNode | undefined => {
  return orgNodes.find((node) => node.id === orgParentId);
};

export const getOrgChildrenByParentList = (
  orgNodes: readonly ClientBusinessNode[],
  parentNode: readonly ClientBusinessNode[],
): ClientBusinessNode[] => {
  return orgNodes.filter((node) =>
    parentNode.find((parent) => parent.id === node.parentId),
  );
};

export function getSiblings(
  nodes: ReadonlyArray<ClientBusinessNode>,
  orgId: string | undefined,
): ReadonlyArray<ClientBusinessNode> {
  return getChildren(nodes, nodes.find((n) => n.id === orgId)?.parentId);
}

export function getSelectBreadCrumbs(
  nodes: ReadonlyArray<ClientBusinessNode>,
  orgId: string | undefined,
): ReadonlyArray<ClientBusinessNode> {
  return getAncestors(nodes, orgId).filter((n) => n.id !== orgId);
}

export function getChildrenWithoutActivities(
  nodes: ReadonlyArray<ClientBusinessNode>,
  orgId: string | undefined,
): ReadonlyArray<ClientBusinessNode> {
  return getChildren(nodes, orgId).filter((n) => n.type !== "activity");
}

export function getAllChildren(
  nodes: ReadonlyArray<ClientBusinessNode>,
  orgId: string | undefined,
): ReadonlyArray<ClientBusinessNode> {
  const node = nodes.find((n) => n.id === orgId);

  if (node === undefined) {
    return [];
  }

  const children = nodes.filter((n) => n.parentId === node.id);
  const subChildren = children.flatMap((c) =>
    getAllChildren(nodes, c.id.toString()),
  );
  return [...children, ...subChildren];
}

export function getUnitFromOfficeLocation(
  location: string,
): { code: string; type: "STO" | "CDC" } | undefined {
  let c: string | undefined = undefined;
  let t: "STO" | "CDC" | undefined = undefined;

  if (location.includes("Store") || location.includes("IKEA")) {
    t = "STO";
  }

  if (location.includes("CDC")) {
    t = "CDC";
  }

  if (t !== undefined) {
    c = location.substring(location.length - 3);
  }

  if (c !== undefined && t !== undefined) {
    return { code: c, type: t };
  }
}

export function getCostCentresFromOrgNode(
  nodes: ReadonlyArray<ClientBusinessNode>,
  org: ClientBusinessNode,
): Set<string> {
  if (org.type !== "department") {
    return new Set(
      getAllChildren(nodes, org.id)
        .filter((n) => n.type === "department") // Get all department nodes --> to get CCs
        .flatMap((n) => n.attributes?.["costCentre"]?.map((cc) => cc.publicId))
        .filter((cc): cc is string => cc !== undefined),
    );
  }

  return new Set(
    org.attributes?.["costCentre"]?.map((cc) => cc.publicId) ?? [],
  );
}
