import formatISO from "date-fns/format";
import { enGB, ja } from "date-fns/locale";
import { TFunction } from "i18next";

import { ApiErrorType } from "@pdpp/lib-planta";

export const displayDateFromISO = (value: string, lang: string): string =>
  formatISO(new Date(value), "dd MMM yyyy", { locale: getLocale(lang) });

export const getFiscalYear = (): [Date, Date] => {
  const d = new Date();
  const y = d.getFullYear();
  const fy = d.getMonth() >= 8 ? y : y - 1;
  const fy0 = new Date(fy, 8, 1); // September 1
  const fy1 = new Date(fy + 1, 7, 31); // August 31
  return [fy0, fy1];
};

export function debounce<A = unknown, R = void>(
  fn: (args: A) => R,
  ms: number,
): (args: A) => void {
  let timer: ReturnType<typeof setTimeout> | null = null;

  return (args: A): void => {
    if (timer) {
      clearTimeout(timer);
    }

    timer = setTimeout(() => {
      fn(args);
      timer = null; // Clear the timer after execution
    }, ms);
  };
}

export const getLocale = (lang: string): Locale => {
  if (lang === "ja-JP") {
    return ja;
  }

  return enGB;
};

export type ApiErrors = {
  errors: ApiErrorType[];
};

export function parseApiErrors(
  errorsObj: ApiErrorType[],
  t: TFunction,
): string[] {
  return errorsObj.map((error) => {
    const translationKey = `serverErrors.${error.code}`;

    return t(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      translationKey,
      error.arguments
        ? Object.fromEntries(Object.entries(error.arguments))
        : {},
    ) as string;
  });
}
