import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { FormikMultiSelect } from "@pdpp/lib-react";

export const weekdays = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
] as const;
export type Weekdays = typeof weekdays;

interface WeekdaysSelectProps {
  readonly name: string;
  readonly value: Weekdays;
  readonly className: string;
}
export const WeekdaysSelect: FC<WeekdaysSelectProps> = ({
  name,
  value,
  className,
}) => {
  const [t] = useTranslation();
  const weekdaysOptions = useMemo(
    () =>
      weekdays.map((weekdayValue) => ({
        value: weekdayValue,
        label: t(`labels.${weekdayValue}`),
      })),
    [t],
  );

  return (
    <FormikMultiSelect
      name={name}
      label={t("labels.weekdays")}
      options={weekdaysOptions}
      value={value}
      className={className}
    />
  );
};
