import { ChangeEvent, ReactNode } from "react";

import Hyperlink from "@ingka/hyperlink";
import Text from "@ingka/text";

interface IntralinkProps {
  readonly text?: string;
  readonly onClick: (event: ChangeEvent) => void;
  readonly url: string;
  readonly disabled?: boolean;
  readonly bold?: boolean;
  readonly className?: string;
  readonly children?: ReactNode;
}

export function Intralink({
  text,
  onClick,
  url,
  disabled,
  bold = false,
  className,
  children,
}: IntralinkProps): JSX.Element {
  return (
    <Hyperlink
      subtle={true}
      button={true}
      disabled={disabled}
      onClick={(event: ChangeEvent) => {
        event.preventDefault();
        onClick(event);
      }}
      url={url}
      className={className}
    >
      {children !== undefined ? (
        children
      ) : (
        <Text
          tagName="span"
          bodySize={bold ? undefined : "s"}
          headingSize={bold ? "xs" : undefined}
          className={className}
        >
          {text}
        </Text>
      )}
    </Hyperlink>
  );
}
