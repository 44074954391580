import { signal, effect, computed } from "@preact-signals/safe-react";
import { format } from "date-fns";

import { getFiscalYear } from "../../utils";
import { decodePlanningPeriod, encodePlanningPeriod } from "./transforms";
import {
  decodeShareableSetting,
  encodeShareableSetting,
} from "../../utils/shareableSetting";
import { HeadCount, PlanViewData } from "../../types/api";
import { getDurationFromHeadCountFormatted } from "../plan/transforms";
import { fmtHhMm } from "../dates/transforms";

const KEY = "period";

type PlanData = PlanViewData["data"];

export const periodPlan$ = signal<PlanData>([]);

export const modifiedPlans$ = signal<Map<string, number[]>>(new Map());

export const periodBudget$ = signal<string>(fmtHhMm(0));

export const period$ = signal(
  decodeShareableSetting(KEY, decodePlanningPeriod) ?? getFiscalYear(),
);

effect(() => {
  const value = period$.value;
  if (value !== undefined) {
    encodeShareableSetting(KEY, value, encodePlanningPeriod);
  }
});

effect(() => {
  //So OPP can also get the period from our client package
  localStorage.setItem(
    "period",
    [
      format(period$.value[0], "yyyy-MM-dd"),
      format(period$.value[1], "yyyy-MM-dd"),
    ].join(","),
  );
});

export const periodPlanTotal$ = computed<string>(() => {
  const periodPlan = periodPlan$.value;

  return getDurationFromHeadCountFormatted(
    periodPlan.flatMap((h) => h.dayQuarters),
  );
});

export const periodHeadCount$ = signal<HeadCount[]>([]);
