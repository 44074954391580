import { ENVIRONMENT } from "../../utils/runtimeConfig";

export const isDev = ENVIRONMENT.startsWith("dev");
export const isProd = ENVIRONMENT.startsWith("prod");
export const isStage =
  ENVIRONMENT.startsWith("stage") || ENVIRONMENT.startsWith("demo");

function isV5(): boolean {
  return window.location.search.includes("version=v5");
}

function isV6(): boolean {
  return window.location.search.includes("version=v6");
}

export const showV5 = isDev || isStage || isV5() || isV6();
export const showV6 = isDev || isV6();
export const showCC = window.location.search.includes("version=cc");
