import { FC } from "react";
import { useTranslation } from "react-i18next";

import SSRIcon from "@ingka/ssr-icon";
import MoneyBill from "@ingka/ssr-icon/paths/money-bills";
import Text from "@ingka/text";

import { css } from "../__generated-styled-system/css";
import { periodBudget$ } from "../features/period";

const wrapperStyles = css({
  display: "grid",
  gridTemplateColumns: "min-content 1fr",
  gridTemplateRows: "min-content 1fr",
  gridRowGap: "space100",
});

const periodPlanTextStyles = css({
  marginLeft: "space50!",
});

const textStyles = css({ gridColumn: "-1 / 1" });

export const PlanBudget: FC = () => {
  const { t } = useTranslation();

  const periodBudget = periodBudget$.value;
  return (
    <div className={wrapperStyles}>
      <Text className={textStyles} tagName="p" bodySize="s">
        {t("plan.periodBudget")}
      </Text>
      <SSRIcon paths={MoneyBill} />
      <Text className={periodPlanTextStyles} tagName="span" headingSize="xs">
        {periodBudget}
      </Text>
    </div>
  );
};
