import { format } from "date-fns";

import { DatePeriod, DateStringPeriod } from "./types";
import { isValidPeriod } from "./validation";
import { getLocale } from "../../utils";

export const periodString = (
  beginDate: Date,
  endDate: Date,
  lang: string,
): string =>
  [beginDate, endDate]
    .map((d) => format(d, "d MMM yyyy", { locale: getLocale(lang) }))
    .join(" - ");

export function periodLocaleString(period: DatePeriod, locale: string): string {
  return period
    .map((d) =>
      new Intl.DateTimeFormat(locale, {
        year: "numeric",
        month: "short",
        day: "numeric",
      }).format(d),
    )
    .join(" - ");
}

// NOTE: This function is used to have correct formatting when sending dates to API
// therefore locale is set to "sv" and not dynamically.
export function periodDateStringPeriod(period: DatePeriod): DateStringPeriod {
  return period.map((date) => date.toLocaleString("sv").slice(0, 10)) as [
    string,
    string,
  ];
}

export function periodFromStringPeriod(p: DateStringPeriod): DatePeriod {
  return [new Date(p[0]), new Date(p[1])];
}

export function decodePlanningPeriod(
  encoded: string | null,
): DatePeriod | undefined {
  if (encoded === null) {
    return undefined;
  }

  // TODO: handle local time offset better
  const period = encoded.split(",").map((d) => new Date(d)) as
    | [Date, Date]
    | undefined;

  if (period?.length === 2 && isValidPeriod(period)) {
    return period;
  }

  return undefined;
}

export function encodePlanningPeriod(period: DatePeriod): string {
  return period
    .map(
      (date) =>
        // TODO: handle local time offset better
        `${date.getFullYear()}-${(date.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`,
    )
    .join(",");
}
