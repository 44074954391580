//Formats minutes to hours and minutes, example --> 5h34
export function fmtHhMm(mins: number): string {
  return `${Math.floor(mins / 60).toLocaleString()}h${(mins % 60)
    .toString()
    .padStart(2, "0")}`;
}

export function getDatesInMonth(
  year: number,
  month: number,
  weekStartsOn: number,
): ReadonlyArray<Date> {
  const firstDayOfMonth = new Date(year, month, 1);
  const firstDayOfWeek = firstDayOfMonth.getDay();
  const result: Array<Date> = [];
  const daysInPrevMonth = new Date(year, month, 0).getDate();

  for (
    let i = daysInPrevMonth - ((firstDayOfWeek - weekStartsOn + 7) % 7) + 1;
    i <= daysInPrevMonth;
    i++
  ) {
    result.push(new Date(year, month - 1, i));
  }

  for (let day = 1; day <= new Date(year, month + 1, 0).getDate(); day++) {
    result.push(new Date(year, month, day));
  }

  const daysToAddNextMonth = 7 - (result.length % 7);

  if (daysToAddNextMonth < 7) {
    for (let i = 1; i <= daysToAddNextMonth; i++) {
      result.push(new Date(year, month + 1, i));
    }
  }

  return result;
}

export function getWeekNumber(date: Date, weekStartsOn: number): number {
  const startOfWeek = new Date(date.getFullYear(), 0, 1);
  const dayOfWeek = startOfWeek.getDay();
  startOfWeek.setDate(
    startOfWeek.getDate() + ((7 - dayOfWeek + weekStartsOn) % 7),
  );

  return Math.ceil(
    ((date.getTime() - startOfWeek.getTime()) / 86400000 + 1) / 7,
  ); // 86400000 ms => 1 day
}

export function getMonthsAndYearsRange(
  startDate: Date,
  endDate: Date,
): ReadonlyArray<{
  readonly year: number;
  readonly month: number;
}> {
  const monthsAndYears: Array<{
    readonly year: number;
    readonly month: number;
  }> = [];
  const currentDate = new Date(startDate);

  while (currentDate <= endDate) {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();

    monthsAndYears.push({ year, month });

    // Move to the next month
    currentDate.setMonth(month + 1);
  }

  return monthsAndYears;
}

export const getMinutes = (values: ReadonlyArray<number>): number => {
  let total = 0;

  for (const value of values) {
    total = total + value;
  }

  return total * 15;
};

// Returns last day of the month, e.g. January => 31, February => 28 or 29
export function getLastDayOfMonth(year: number, month: number): number {
  return new Date(year, month + 1, 0).getDate();
}

// Returns first day date objects in each week
export function getFirstDayWeeksInRange(
  startDate: Date,
  endDate: Date,
  weekStartsOn: number,
): ReadonlyArray<Date> {
  const dayStrings: Array<string> = [];
  const days: Array<Date> = [];
  const currentDate = new Date(startDate);

  while (currentDate <= endDate) {
    const year = currentDate.getFullYear();
    const firstDayOfYear = new Date(year, 0, 1);
    const daysToFirstWeekDay = (7 - firstDayOfYear.getDay() + weekStartsOn) % 7;
    const weekNumber = getWeekNumber(currentDate, weekStartsOn);
    const firstDayOfWeek = new Date(
      year,
      0,
      1 + daysToFirstWeekDay + (weekNumber - 1) * 7,
    );

    const month = (firstDayOfWeek.getMonth() + 1).toString().padStart(2, "0");
    const date = firstDayOfWeek.getDate().toString().padStart(2, "0");
    const dayString = `${firstDayOfWeek.getFullYear()}-${month}-${date}`;

    if (!dayStrings.includes(dayString)) {
      dayStrings.push(dayString);
    }

    // Move to the next day
    currentDate.setDate(currentDate.getDate() + 1);
  }

  for (const day of dayStrings) {
    days.push(new Date(day));
  }

  return days;
}

// Returns date objects in each week
export function getDaysInWeek(
  year: number,
  week: number,
  weekStartsOn: number,
): ReadonlyArray<Date> {
  const firstDayOfYear = new Date(year, 0, 1);
  const daysToFirstWeekDay = (7 - firstDayOfYear.getDay() + weekStartsOn) % 7;
  const firstWeekStartDate = new Date(
    year,
    0,
    1 + daysToFirstWeekDay + (week - 1) * 7,
  );

  const weekDates = new Array(7);

  for (let i = 0; i < 7; i++) {
    const date = new Date(firstWeekStartDate);

    date.setDate(firstWeekStartDate.getDate() + i);

    weekDates[i] = date;
  }

  return weekDates;
}
