import {
  FC,
  ReactNode,
  SyntheticEvent,
  useCallback,
  useMemo,
  useState,
  useEffect,
} from "react";
import { useTranslation } from "react-i18next";

import {
  ConfigProvider,
  Layout,
  ActiveUser,
  ProfileSection,
  HeaderButton,
  NavItemModelReact,
} from "@coworker-design/layout-react";
import Modal, { ModalHeader, Sheets } from "@ingka/modal";
import ListView, { ListViewItem } from "@ingka/list-view";
import QuestionMarkIcon from "@ingka/ssr-icon/paths/question-mark-circle.js";
import {
  getAccessibleApps,
  getOppURL,
  isOpp,
  getOppCookie,
  Environments,
} from "@pdpp/lib-react";

import { css, cx } from "../__generated-styled-system/css";
import { NOW_IT_LINK } from "../config/help";
import { getSupportedLanguage } from "../language/language";
import { Language } from "./Language";
import { Auth } from "../api/auth";
import { ENVIRONMENT } from "../utils/runtimeConfig";
import { setShareableSetting } from "../utils/shareableSetting";

const PROJECT_NAME = "Workload planning";
const USER_MANUAL_URL =
  "https://iweof.sharepoint.com/sites/ms/rwm/hr/globalpeopleplanninghub/Shared%20Documents/Forms/AllItems.aspx?OR=Teams%2DHL&CT=1688719067061&clickparams=eyJBcHBOYW1lIjoiVGVhbXMtRGVza3RvcCIsIkFwcFZlcnNpb24iOiIyOC8yMzA2MDQwMTE2MSIsIkhhc0ZlZGVyYXRlZFVzZXIiOmZhbHNlfQ%3D%3D&id=%2Fsites%2Fms%2Frwm%2Fhr%2Fglobalpeopleplanninghub%2FShared%20Documents%2FWorkload%20planning%2F1%2E%20User%20manual%2FUserManual%5FWorkload%5FPlanning%2Epdf&viewid=c208c346%2D51fe%2D4b31%2D8f17%2D1f3c05a2d804&parent=%2Fsites%2Fms%2Frwm%2Fhr%2Fglobalpeopleplanninghub%2FShared%20Documents%2FWorkload%20planning%2F1%2E%20User%20manual";
const IS_PROD = ENVIRONMENT.startsWith("prod");

const supportItemStyles = css({
  whiteSpace: "pre-wrap",
});

/**
 * Temporary fix for Firefox
 * so that the header's content
 * is not centered:
 * https://jira.digital.ingka.com/servicedesk/customer/portal/409/ICWDS-427
 */
const layoutStyles = css({
  "& .cwds-header__container": {
    width: "100%",
  },
});

const layoutDevStyles = css({
  "& .cwds-header__container": {
    backgroundImage: `url('data:image/svg+xml;utf8,<svg style="transform:rotate(45deg)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 40"><text x="0" y="25" fill="%23fff" fill-opacity="0.2" font-family="Noto IKEA, Helvetica, sans-serif" font-size="0.7rem">dev</text></svg>')
    `,
  },
});

const layoutDemoStyles = css({
  "& .cwds-header__container": {
    backgroundImage: `url('data:image/svg+xml;utf8,<svg style="transform:rotate(45deg)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 40"><text x="0" y="25" fill="%23fff" fill-opacity="0.2" font-family="Noto IKEA, Helvetica, sans-serif" font-size="0.7rem">demo</text></svg>')
    `,
  },
});

const layoutStageStyles = css({
  "& .cwds-header__container": {
    backgroundImage: `url('data:image/svg+xml;utf8,<svg style="transform:rotate(45deg)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 40"><text x="0" y="25" fill="%23fff" fill-opacity="0.2" font-family="Noto IKEA, Helvetica, sans-serif" font-size="0.7rem">stage</text></svg>')`,
  },
});

const getLayoutStyles = ():
  | typeof layoutDemoStyles
  | typeof layoutDevStyles
  | typeof layoutStageStyles
  | undefined => {
  if (ENVIRONMENT.includes("demo")) {
    return layoutDemoStyles;
  } else if (ENVIRONMENT.includes("dev") && !ENVIRONMENT.includes("demo")) {
    return layoutDevStyles;
  } else if (ENVIRONMENT.startsWith("stage")) {
    return layoutStageStyles;
  }
  return undefined;
};

export type NavItemWithClick = NavItemModelReact & {
  readonly onClick: VoidFunction;
};

enum Support {
  default = "Default",
  raiseTicket = "RaiseTicket",
}

interface AppLayoutProps {
  readonly children: ReactNode;
  readonly navItems: Array<NavItemWithClick>;
}

export const AppLayout: FC<AppLayoutProps> = ({ navItems, children }) => {
  const [t] = useTranslation();
  const [supportContent, setSupportConent] = useState<Support | null>(null);
  const [user, setUser] = useState<ActiveUser | undefined>(undefined);

  useEffect(() => {
    Auth.get()
      .getUser()
      .then((p) => {
        if (p === undefined) {
          return;
        }
        const pp = p.profile;
        setUser({
          country: pp.country,
          given_name: pp.givenName,
          family_name: pp.surname,
          email: pp.mail,
          jobTitle: pp.jobTitle,
          businessUnit: pp.officeLocation,
          name: pp.displayName,
          picture: p.photo,
        });
      });
  }, []);

  const fullHref = window.location.href;
  const env: Environments =
    fullHref.includes("dev") || fullHref.includes("localhost")
      ? "dev"
      : fullHref.includes("demo")
        ? "demo"
        : fullHref.includes("stage")
          ? "stage"
          : "prod";

  const isVersion2 = fullHref.includes("version=2");

  const accessibleApps = useMemo(() => {
    return getAccessibleApps("Workload planning", isVersion2, env);
  }, [env, isVersion2]);
  const isOppHeader = useMemo(() => isOpp(), []);
  const labels = useMemo(
    () => ({
      appSwitcher: {
        goToHome: t("nav.goToHome", {
          home: "One people planning",
        }),
        title: t("nav.appSwitcherTitle", {
          project: "People planning",
        }),
      },
      userProfile: {
        signOut: t("nav.signOut"),
      },
    }),
    [t],
  );
  const sections = useMemo<Array<ProfileSection<ReactNode>>>(
    () => [
      {
        title: t("language.preferredLanguage"),
        description: getSupportedLanguage(getOppCookie().lang),
        template: <Language />,
      },
    ],
    [t],
  );
  const utilities = useMemo(
    () => (
      <>
        <HeaderButton
          ssrIcon={QuestionMarkIcon}
          onClick={() => {
            setSupportConent(Support.default);
          }}
          data-analytics="support"
        />
      </>
    ),
    [],
  );
  const handleSupportBackBtnClick = useMemo(
    () =>
      supportContent === Support.raiseTicket
        ? () => setSupportConent(Support.default)
        : undefined,
    [supportContent],
  );

  const handleLocationChange = useCallback(
    (href: string, _target: string, event: SyntheticEvent) => {
      /**
       * This function is run for all the location
       * changes so call `onClick` for navigation items only
       */
      const match = navItems.find((item) => item.href === href);

      if (match !== undefined) {
        event.preventDefault();

        match.onClick();
      }
    },
    [navItems],
  );
  const handleLogout = useCallback(
    () =>
      Auth.get().logout({
        postLogoutRedirectUri: "/",
      }),
    [],
  );
  const handleSupportClick = useCallback(
    () => setSupportConent(Support.raiseTicket),
    [],
  );
  const closeSupportDialog = useCallback(() => setSupportConent(null), []);
  const handleTitleClick = useCallback(() => {
    // Set go to the Unit select in initial page without triggering automatic selection of a Units
    setShareableSetting("orgNode", "-1");
    window.location.reload();
  }, []);
  const handleHomeClick = useCallback(() => {
    window.location.href = getOppURL(IS_PROD);
  }, []);

  return (
    <>
      <ConfigProvider
        isAppSwitcherShown={isOppHeader}
        accessibleApps={accessibleApps}
        isShowingBottomNav={false}
        headerNavMode="HEADER_LINKS"
        isSeeAllAppsShown={false}
        labels={labels}
        languagePicker={null}
        navItems={navItems}
        onLocationChange={handleLocationChange}
        onHomeClick={handleHomeClick}
        onTitleClick={handleTitleClick}
        sections={sections}
        user={user}
        userProfileSectionListMode="LARGE"
      >
        <Layout
          className={cx(layoutStyles, getLayoutStyles())}
          logoutCallback={handleLogout}
          narrowContent={false}
          title={PROJECT_NAME}
          utilities={utilities}
        >
          {children}
        </Layout>
      </ConfigProvider>
      <Modal
        handleCloseBtn={closeSupportDialog}
        visible={supportContent !== null}
      >
        <Sheets
          header={
            <ModalHeader
              title={t("support.title")}
              floating={false}
              backBtnClick={handleSupportBackBtnClick}
            />
          }
          footer={null}
          alignment={"right"}
          size="small"
        >
          <>
            {supportContent === Support.default && (
              <ListView id="appLayout">
                <ListViewItem
                  title={t("support.manual.title", {
                    project: PROJECT_NAME,
                  })}
                  description={t("support.manual.description")}
                  emphasised={true}
                  href={USER_MANUAL_URL}
                  control="link"
                  controlIcon="linkOut"
                  newWindow="_blank"
                  rel="noopener noreferrer nofollow"
                  inset={true}
                  data-analytics="user-manual-link"
                />
                <ListViewItem
                  title={t("support.title")}
                  control="navigational"
                  controlIcon="chevronRightSmall"
                  inset={true}
                  emphasised={true}
                  onClick={handleSupportClick}
                  data-analytics="support/options"
                />
              </ListView>
            )}
            {supportContent === Support.raiseTicket && (
              <ListView id="support">
                <ListViewItem
                  className={supportItemStyles}
                  title={t("support.raiseTicket.title")}
                  description={t("support.raiseTicket.description", {
                    project: PROJECT_NAME,
                    areas: "HR tools / Property / Learning tools",
                  })}
                  control="link"
                  controlIcon="linkOut"
                  inset
                  emphasised
                  href={NOW_IT_LINK}
                  newWindow="_blank"
                  data-analytics="support/options/raise-ticket"
                />
              </ListView>
            )}
          </>
        </Sheets>
      </Modal>
    </>
  );
};
