import { useEffect } from "react";

import { VITE_TRACKER_URL } from "../utils/runtimeConfig";
import { Auth } from "../api/auth";
import { MSAL_SCOPES } from "../api/fetch";

function getAccessToken(): Promise<string> {
  return Auth.get().getToken({ scopes: MSAL_SCOPES });
}

const SCRIPT_ID = "t";

async function setup(): Promise<void> {
  const user = await Auth.get().getUser();

  if (user?.profile.mail === undefined || !VITE_TRACKER_URL) {
    return;
  }

  {
    let script = document.getElementById(SCRIPT_ID) as HTMLScriptElement | null;
    if (script !== null) {
      return;
    }

    script = document.createElement("script");
    script.id = SCRIPT_ID;
    script.src = VITE_TRACKER_URL + "/t.js";
    script.async = true;

    const event = new CustomEvent("start-tracking", {
      detail: {
        user: {
          id: user.profile.mail,
          country: user.profile.country,
          unit: { id: user.unit },
        },
        getAccessToken,
        trackerUrl: VITE_TRACKER_URL,
      },
    });

    script.onload = () => window.dispatchEvent(event);
    document.body.appendChild(script);
  }
}

function teardown(): void {
  const script = document.getElementById(SCRIPT_ID) as HTMLScriptElement | null;
  if (script !== null) {
    document.body.removeChild(script);
  }
}

export const useAnalytics = (): void => {
  useEffect(() => {
    // HACK: there's a race condition here with setup/teardown, why is this in a hook?
    // why do we even need teardown?
    setup();
    return teardown;
  }, []);
};
