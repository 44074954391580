import { setLocale } from "yup";

import { i18n } from "./i18n";

export const setDefaultYupTranslations = (): void =>
  setLocale({
    mixed: {
      default: i18n.t("common:validation.invalidField"),
      notType: ({ type }: { readonly type: string }) =>
        type === "number"
          ? i18n.t("common:validation.errorNumbersOnly")
          : i18n.t("common:validation.wrongType", { type }),
      required: i18n.t("common:validation.isRequired"),
    },
    number: {
      integer: i18n.t("common:validation.errorIntegersOnly"),
      lessThan: ({ less }: { readonly less: number }) =>
        i18n.t("common:validation.outOfRangeMax", { max: less }),
      max: ({ max }: { readonly max: number }) =>
        i18n.t("common:validation.outOfRangeMax", { max: max + 1 }),
      min: ({ min }: { readonly min: number }) =>
        i18n.t("common:validation.outOfRangeMin", { min: min - 1 }),
      moreThan: ({ more }: { readonly more: number }) =>
        i18n.t("common:validation.outOfRangeMin", { min: more }),
      negative: i18n.t("common:validation.negativeNumber"),
      positive: i18n.t("common:validation.positiveNumber"),
    },
    string: {
      email: i18n.t("common:validation.invalidEmail"),
      length: ({ length }: { readonly length: number }) =>
        i18n.t("common:validation.exactLength", { exactLength: length }),
      matches: ({ regex }: { regex: RegExp }) =>
        i18n.t("common:validation.noMatch", { regex }),
      max: ({ max }: { readonly max: number }) =>
        i18n.t("common:validation.maxLength", { maxLength: max }),
      min: ({ min }: { readonly min: number }) =>
        i18n.t("common:validation.minLength", { minLength: min }),
      url: i18n.t("common:validation.invalidUrl"),
    },
  });
