import { format } from "date-fns";
import { useMemo, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { t } from "i18next";

import type { WebApi } from "@pdpp/planta-server";
import { ResponseNotOkError } from "@pdpp/lib-react";

import { plantaApi } from "../../api/fetch";
import { DatePeriod } from "../period";
import { Weekdays, weekdays } from "../../components/WeekdaysSelect";
import { Template } from "../overview/types";
import { ApiErrors, parseApiErrors } from "../../utils";

//NOTE: One approach on how to reuse types from backend,
export type DayTemplate = WebApi["postDayTemplate"]["input"]["body"];
export type DayTemplateDatesInput = WebApi["postDayTemplateDates"]["input"];

export interface PlanMethodAssigned {
  readonly name: string;
  readonly dates: ReadonlyArray<string>;
  readonly id: number;
}

export interface PlanMethod {
  readonly id: string;
  readonly name: string;
  readonly orgActivityIds: Array<number>;
  readonly validPeriod: DatePeriod;
  readonly templates: Template[];
  readonly dates: PlanMethodAssigned[];
  readonly workloadDriverName: string;
  readonly workloadDriverLabel: string;
  readonly workloadDriverSubType: string;
}

export interface PlanMethodFromApi extends Omit<PlanMethod, "validPeriod"> {
  readonly validPeriod: {
    start: string;
    end: string;
  };
}

interface UseGetMethodsProps {
  readonly orgNodeId: string;
}

interface UseGetMethods {
  readonly data: ReadonlyArray<PlanMethod>;
  readonly isLoading: boolean;
  readonly refetch: () => void;
}

export const useGetMethods = ({
  orgNodeId,
}: UseGetMethodsProps): UseGetMethods => {
  const {
    isPending: isLoading,
    data: methods,
    refetch,
  } = useQuery<PlanMethodFromApi[]>({
    queryKey: [`plan-methods-${orgNodeId}`],
    queryFn: async ({ signal }) => {
      const res = await plantaApi.jsonInJsonOut<PlanMethodFromApi[]>(
        `/plan/methods/?orgId=${orgNodeId}&orgType=department`,
        { method: "GET", signal },
      );
      return res;
    },
  });

  const methodsWithDeps = useQuery<Array<PlanMethodFromApi>>({
    queryKey: [`plan-methods-with-deps-${orgNodeId}`],
    queryFn: async ({ signal }) => {
      if (!methods) return [];
      const enhancedMethods = await Promise.all(
        methods.map(async (method: PlanMethodFromApi) => {
          const templates = await plantaApi.jsonInJsonOut<Template[]>(
            `/plan/dayTemplateMethods/${method.id}/dayTemplates`,
            { signal },
          );
          const dates = await plantaApi.jsonInJsonOut<PlanMethodAssigned[]>(
            `/plan/dayTemplateMethods/${method.id}/dates`,
            { signal },
          );

          return {
            ...method,
            templates,
            dates,
          };
        }),
      );
      return enhancedMethods;
    },
    staleTime: 1000 * 60 * 5, // 5 minutes
  });

  useEffect(() => {
    methodsWithDeps.refetch();
  }, [methodsWithDeps, methodsWithDeps.refetch]);

  return {
    data:
      methodsWithDeps.data?.map((d) => ({
        ...d,
        validPeriod: [
          new Date(d.validPeriod.start),
          new Date(d.validPeriod.end),
        ],
      })) ?? [],
    isLoading: isLoading || methodsWithDeps.isLoading,
    refetch,
  };
};

export const useGetMethod = ({
  id,
}: {
  id: string;
}): {
  data: PlanMethod;
  refetch: () => void;
  dayTemplatesRefetch: () => void;
} => {
  const { data: method, refetch } = useQuery<PlanMethodFromApi>({
    initialData: {
      id: "",
      name: "",
      orgActivityIds: [],
      validPeriod: { start: new Date().toString(), end: new Date().toString() },
      templates: [],
      dates: [],
      workloadDriverName: "",
      workloadDriverLabel: "",
      workloadDriverSubType: "",
    },
    queryKey: [`plan-method-${id}`],
    queryFn: async ({ signal }) => {
      const res = await plantaApi.jsonInJsonOut<PlanMethodFromApi>(
        `/plan/methods/${id}`,
        { signal },
      );
      return res;
    },
  });

  const { data: dates } = useQuery<PlanMethodAssigned[]>({
    initialData: [],
    queryKey: [`plan-method-dates-${id}`],
    queryFn: async ({ signal }) => {
      const res = await plantaApi.jsonInJsonOut<PlanMethodAssigned[]>(
        `/plan/dayTemplateMethods/${id}/dates`,
        { signal },
      );
      return res;
    },
  });

  const { data: dayTemplates, refetch: dayTemplatesRefetch } = useQuery<
    Template[]
  >({
    queryKey: [`plan-day-template-${id}`],
    queryFn: async ({ signal }) => {
      const res = await plantaApi.jsonInJsonOut<Template[]>(
        `/plan/dayTemplateMethods/${id}/dayTemplates`,
        { signal },
      );
      return res;
    },
  });

  const templates = useMemo<Template[]>((): Template[] => {
    return (dayTemplates as Template[])?.map((template: Template) => {
      const tCopy = JSON.parse(JSON.stringify(template));
      if (
        tCopy.conditions !== undefined &&
        tCopy.conditions.weekdays !== undefined
      ) {
        tCopy.conditions.weekdays = tCopy.conditions.weekdays.map(
          (x: number) => weekdays[x - 1],
        ) as Weekdays;
      }
      return tCopy;
    });
  }, [dayTemplates]);

  return {
    data: {
      ...method,
      templates: templates ?? [],
      dates,
      validPeriod: [
        new Date(method.validPeriod.start ?? ""),
        new Date(method.validPeriod.end ?? ""),
      ] as DatePeriod,
    },
    refetch,
    dayTemplatesRefetch,
  };
};

interface PutPlanMethodArgs
  extends Omit<
    PlanMethod,
    "id" | "templates" | "dates" | "workloadDriverLabel"
  > {
  id?: string | undefined;
}

interface PutPlanMethodReturn {
  readonly errors: ReadonlyArray<string>;
}
export async function putPlanMethod(
  values: PutPlanMethodArgs,
  departmentId: number,
): Promise<PutPlanMethodReturn | void> {
  const body = {
    items: [
      {
        base: {
          id: values.id,
          name: values.name,
          type: "DayTemplate",
          departmentId: departmentId,
          orgActivityIds: values.orgActivityIds,
          validPeriod: {
            start: format(values.validPeriod[0], "yyyy-MM-dd"),
            end: format(values.validPeriod[1], "yyyy-MM-dd"),
          },
          workloadDriverName: values.workloadDriverName || null,
          workloadDriverSubType: values.workloadDriverSubType || null,
        },
        //NOTE: In future when other planning methods than DayTemplate, this should be used.
        //variant: {}
      },
    ],
  };

  try {
    await plantaApi.jsonInVoidOut(`/plan/methods`, {
      method: "PUT",
      body: JSON.stringify(body),
    });
  } catch (error) {
    if (error instanceof ResponseNotOkError) {
      const errors = (await error.response.json()) as ApiErrors;

      return { errors: parseApiErrors(errors.errors, t) };
    }

    return { errors: [t("notifications.somethingWentWrong")] };
  }
}

interface DeletePlanMethodArgs {
  readonly id: string;
}

export async function deletePlanMethod({
  id,
}: DeletePlanMethodArgs): Promise<void> {
  return await plantaApi.jsonInVoidOut(`/plan/methods/${id}`, {
    method: "DELETE",
  });
}

export async function assignDays(
  params: DayTemplateDatesInput["params"],
  body: DayTemplateDatesInput["body"],
): Promise<void> {
  return await plantaApi.jsonInVoidOut(
    `/plan/dayTemplates/${params.id}/dates`,
    {
      method: "POST",
      body: JSON.stringify(body),
    },
  );
}

export async function createTemplate(values: DayTemplate): Promise<void> {
  return await plantaApi.jsonInVoidOut(`/plan/dayTemplates`, {
    method: "POST",
    body: JSON.stringify(values),
  });
}

export async function editTemplate(values: Template): Promise<void> {
  return await plantaApi.jsonInVoidOut(`/plan/dayTemplates/${values.id}`, {
    method: "PUT",
    body: JSON.stringify(values),
  });
}

export async function deleteTemplate(id: number): Promise<void> {
  return await plantaApi.jsonInVoidOut(`/plan/dayTemplates/${id}`, {
    method: "DELETE",
  });
}
