import { getQueryParam, setQueryParam } from "./queryParams";

export function getShareableSetting(key: string): string | null {
  return getQueryParam(key) ?? localStorage.getItem(key);
}

export function decodeShareableSetting<T>(
  key: string,
  decoder: (encoded: string | null) => T | undefined,
): T | undefined {
  return decoder(getQueryParam(key)) ?? decoder(localStorage.getItem(key));
}

export function encodeShareableSetting<T>(
  key: string,
  value: T,
  encoder: (value: T) => string,
): void {
  const encoded = encoder(value);
  setShareableSetting(key, encoded);
}

export function setShareableSetting(key: string, value: string): void {
  setQueryParam(key, value);
  localStorage.setItem(key, value);
}
