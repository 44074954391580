import { FetchApi } from "@pdpp/lib-react";

import { VITE_API_URL, VITE_APP_CLIENT_ID } from "../utils/runtimeConfig";
import { Auth } from "./auth";

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 *
 * Add here the scopes to request when obtaining an access token for MS Graph API. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */

//4b81ac6b-942c-4452-a0d6-b6fa37df66af for PLANTA
export const MSAL_SCOPES = [`api://${VITE_APP_CLIENT_ID}/api-access`];

export const plantaApi = new FetchApi(VITE_API_URL, Auth.get(), MSAL_SCOPES);
