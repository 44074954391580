import { DatePeriod } from "./types";

export function isValidPeriod([d0, d1]: DatePeriod): boolean {
  const USER_ALLOWED_YEAR_RANGE = 2;

  const now = new Date();

  const min = new Date(now);
  min.setFullYear(now.getFullYear() - USER_ALLOWED_YEAR_RANGE);

  const max = new Date(now);
  max.setFullYear(now.getFullYear() + USER_ALLOWED_YEAR_RANGE);

  return d0 <= d1 && d0 >= min && d1 <= max;
}
