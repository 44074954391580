import { FC, ReactNode } from "react";

import Text from "@ingka/text";

import { css, cx } from "../__generated-styled-system/css";

const wrapperStyles = css({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  gap: "space100",
  backgroundColor: "colourStaticWhite",
  textAlign: "left",
  borderRadius: "radiusM",
  paddingX: "space150!",
  paddingY: "space300!",
});
const shadowStyles = css({
  boxShadow: "shadowSizeDefault",
});

interface CardProps {
  readonly title: string;
  readonly message: string;
  readonly content?: ReactNode;
  readonly applyShadow?: boolean;
}

export const Card: FC<CardProps> = ({
  title,
  message,
  content,
  applyShadow,
}) => (
  <div
    className={
      applyShadow === false ? wrapperStyles : cx(wrapperStyles, shadowStyles)
    }
  >
    <Text tagName="span" headingSize="m">
      {title}
    </Text>
    <Text tagName="span" bodySize="m">
      {message}
    </Text>
    {content}
  </div>
);
