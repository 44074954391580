import { Signal, effect, signal } from "@preact-signals/safe-react";

import { HeadCount, PublishedChange } from "../../types/api";
import { period$ } from "../period/state";
import { View } from "../../types/client";

const params = new URLSearchParams(window.location.search);
const view = params.get("view");

const initialView =
  view === View.Day ? View.Day : view === View.Week ? View.Week : View.Period;

export const publishedPlan$: Signal<PublishedChange | undefined> =
  signal(undefined);
export const isLoadingPublishedPlan$: Signal<boolean> = signal(false);
export const isLoadingHeadcount$: Signal<boolean> = signal(false);
export const headcount$: Signal<ReadonlyArray<HeadCount>> = signal([]);
export const view$: Signal<View> = signal(initialView);
export const paginationDate$: Signal<Date> = signal(period$.value[0]);

// Sets `paginationDate$` to the first value in `period$` signal
// when `period$` changes
effect(() => {
  const period = period$.value;

  paginationDate$.value = period[0];
});

effect(() => {
  localStorage.setItem("view", view$.value);
});
