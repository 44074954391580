import { FC, useState, useCallback, useMemo, MouseEvent } from "react";
import {
  format,
  startOfToday,
  parse,
  add,
  eachDayOfInterval,
  startOfWeek,
  endOfWeek,
  endOfMonth,
  endOfDay,
  isEqual,
  isToday,
  set,
  isBefore,
  isAfter,
} from "date-fns";
import { useTranslation } from "react-i18next";

import Button from "@ingka/button";
import Text from "@ingka/text";
import ChevronLeftIcon from "@ingka/ssr-icon/paths/chevron-left";
import ChevronRightIcon from "@ingka/ssr-icon/paths/chevron-right";
import { PopOver, PopOverProps } from "@pdpp/lib-react";

import { css, cx } from "../__generated-styled-system/css";
import { periodString } from "../features/period";
import { getLocale } from "../utils";
import { weekStartsOn$ } from "../features/dates/state";

const DATE_FORMAT = "MMM yyyy";

const containerStyles = css({
  display: "grid",
  gridTemplateColumns: "repeat(8, 2rem)",
  alignItems: "center",
  justifyItems: "center",
  gap: "space50",
  padding: "space100!",
  backgroundColor: "colourStaticWhite",
  borderRadius: "radiusM",
  boxShadow: "shadowSizeDefault",
});

const dateHeaderStyles = css({
  gridColumn: "2 / 8",
});

const buttonStyles = css({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "100% !important",
  width: "2rem",
  height: "2rem",
});

const navIconStyles = css({
  cursor: "pointer",
});

const footerStyles = css({
  display: "grid",
  gridTemplateRows: "1rem auto",
  gridTemplateColumns: "1fr",
  gap: "space50",
  width: "200px",
  gridColumn: "3 / 7",
});

const footerPeriodStyles = css({
  justifySelf: "center",
});

const footerButtonStyles = css({
  width: "100%",
});

interface DayProps {
  readonly day: Date;
  readonly index: number;
  readonly selectedRange?: Readonly<[Date, Date]> | undefined;
  /**
   * When this prop is passed only
   * the values within the range
   * are possible to select
   * in the picker
   */
  readonly validRange?: Readonly<[Date, Date]> | undefined;
  readonly firstDayCurrentMonth: Date;
  readonly onChange: (start: Date, end: Date) => void;
}

const Day: FC<DayProps> = ({
  day,
  index,
  selectedRange,
  validRange,
  onChange,
}) => {
  const onDatePicked = useCallback(() => {
    // If end and start date are the same day
    if (!selectedRange) {
      onChange(day, day);

      return;
    }

    // If new start date selected is before, the range is expanded
    if (day < selectedRange[0]) {
      onChange(day, selectedRange[1]);

      // If new end date selected is after, the range is expanded
    } else if (day > selectedRange[1]) {
      onChange(selectedRange[0], day);
    } else {
      /**
       * If new date is in between the range,
       * the chosen date is new start date and end date
       */
      onChange(day, day);
    }
  }, [selectedRange, onChange, day]);

  const dayButton = useMemo(() => {
    const endOfToday = endOfDay(day);
    const disabled =
      (validRange !== undefined && isBefore(endOfToday, validRange?.[0])) ||
      (validRange !== undefined && isAfter(day, validRange?.[1]));

    /**
     * Depending on the timezone, the hours may start with non-zero
     * hour which will break the comparison of the dates in the picker
     * so resetting the hours, minutes, seconds and milliseconds
     * to zero when comparing with the range
     */
    const isSelectedDate =
      (selectedRange &&
        (isEqual(
          day,
          set(selectedRange[0], {
            hours: 0,
            minutes: 0,
            seconds: 0,
            milliseconds: 0,
          }),
        ) ||
          isEqual(
            day,
            set(selectedRange[1], {
              hours: 0,
              minutes: 0,
              seconds: 0,
              milliseconds: 0,
            }),
          ))) ??
      false;
    const isInSelectedRange =
      (!isSelectedDate &&
        selectedRange &&
        day <= selectedRange[1] &&
        day >= selectedRange[0]) ??
      false;

    return (
      <button
        type="button"
        disabled={disabled}
        onClick={onDatePicked}
        className={cx(
          buttonStyles,
          css({
            backgroundColor: isSelectedDate
              ? "colourStaticIkeaBrandBlue!"
              : isInSelectedRange
                ? "colourCommercialMessageIkeaFamily!"
                : undefined,
            color: disabled
              ? "colourInteractiveDisabled2!"
              : isSelectedDate || isInSelectedRange
                ? "colourStaticWhite!"
                : !disabled && !isSelectedDate
                  ? "colourNeutral7!"
                  : undefined,
            cursor: disabled ? "initial!" : undefined,
            borderStyle: isToday(day) ? "solid!" : undefined,
            borderWidth: isToday(day) ? "2px!" : undefined,
            borderColor: isToday(day)
              ? "colourStaticIkeaBrandYellow!"
              : undefined,
            fontWeight: isSelectedDate || isToday(day) ? "bold!" : undefined,
            "&:hover": {
              backgroundColor:
                !disabled && !isSelectedDate ? "colourNeutral2!" : undefined,
            },
          }),
        )}
      >
        {format(day, "d")}
      </button>
    );
  }, [day, selectedRange, onDatePicked, validRange]);

  // Include week number for the first day of the week
  if (index % 7 === 0) {
    return (
      <>
        <Text tagName="span" headingSize="xs">
          {format(day, "w")}
        </Text>
        {dayButton}
      </>
    );
  }

  return <>{dayButton}</>;
};

const currentDate = new Date();
const twoYearsAgoStartOfMonth = new Date(currentDate);
twoYearsAgoStartOfMonth.setFullYear(
  currentDate.getFullYear() - 2,
  currentDate.getMonth() + 1,
  1,
);

const twoYearsAheadStartOfMonth = new Date(currentDate);
twoYearsAheadStartOfMonth.setFullYear(
  currentDate.getFullYear() + 2,
  currentDate.getMonth() - 1,
  1,
);

export interface DatePickerRangeProps
  extends Pick<
    PopOverProps,
    | "anchorEl"
    | "onScroll"
    | "horizontalPosition"
    | "horizontalAlignment"
    | "verticalPosition"
    | "verticalAlignment"
    | "clickAwayHandler"
  > {
  readonly onChange: (start: Date, end: Date) => void;
  readonly selectedRange?: Readonly<[Date, Date]> | undefined;
  readonly validRange?: Readonly<[Date, Date]> | undefined;
  /**
   * Locale to be used, defaults to `en-US`
   */
  readonly locale?: string;
  readonly onApply?: (e: MouseEvent<HTMLButtonElement>) => void;
}

export const DatePickerRange: FC<DatePickerRangeProps> = ({
  onChange,
  locale = "en-US",
  selectedRange,
  validRange,
  anchorEl,
  onScroll,
  verticalAlignment = "bottom",
  horizontalAlignment = "right",
  horizontalPosition = "right",
  verticalPosition = "bottom",
  onApply,
  clickAwayHandler,
}) => {
  const [t, i18n] = useTranslation();
  const [currentMonth, setCurrentMonth] = useState(
    format(
      selectedRange !== undefined ? selectedRange[0] : startOfToday(),
      DATE_FORMAT,
    ),
  );

  const firstDayCurrentMonth = useMemo(
    () => parse(currentMonth, DATE_FORMAT, new Date()),
    [currentMonth],
  );
  const weekdays = useMemo(
    () =>
      Array.from({ length: 7 }, (_, index) =>
        new Date(
          Date.UTC(2017, 0, 1 + (index + weekStartsOn$.value)),
        ).toLocaleString(locale, {
          weekday: "long",
        }),
      ),
    [locale],
  );
  const daysInMonth = useMemo(
    () =>
      eachDayOfInterval({
        start: startOfWeek(firstDayCurrentMonth),
        end: endOfWeek(endOfMonth(firstDayCurrentMonth)),
      }),
    [firstDayCurrentMonth],
  );
  /**
   * Translates `Week` word according to the used locale
   * and returns the first character
   */
  const weekTitle = useMemo(
    () =>
      new Intl.DisplayNames(locale, { type: "dateTimeField" })
        .of("weekOfYear")?.[0]
        ?.toUpperCase() ?? "",
    [locale],
  );

  const onPrevNavigationClick = useCallback((): void => {
    setCurrentMonth(
      format(
        add(firstDayCurrentMonth, {
          months: -1,
        }),
        DATE_FORMAT,
      ),
    );
  }, [firstDayCurrentMonth]);
  const onNextNavigationClick = useCallback((): void => {
    setCurrentMonth(
      format(
        add(firstDayCurrentMonth, {
          months: 1,
        }),
        DATE_FORMAT,
      ),
    );
  }, [firstDayCurrentMonth]);

  const memoizedValidRange = useMemo(() => {
    return validRange
      ? validRange
      : ([twoYearsAgoStartOfMonth, twoYearsAheadStartOfMonth] as const);
  }, [validRange]);

  return (
    <PopOver
      anchorEl={anchorEl}
      horizontalAlignment={horizontalAlignment}
      horizontalPosition={horizontalPosition}
      verticalPosition={verticalPosition}
      verticalAlignment={verticalAlignment}
      onScroll={onScroll}
      clickAwayHandler={clickAwayHandler}
    >
      <div className={containerStyles}>
        <Button
          type="tertiary"
          className={navIconStyles}
          onClick={onPrevNavigationClick}
          disabled={firstDayCurrentMonth < twoYearsAgoStartOfMonth}
          ssrIcon={ChevronLeftIcon}
          small
          iconOnly
        />

        <Text tagName="h5" headingSize="xs" className={dateHeaderStyles}>
          {format(firstDayCurrentMonth, DATE_FORMAT, {
            locale: getLocale(i18n.language),
          })}
        </Text>
        <Button
          type="tertiary"
          className={navIconStyles}
          onClick={onNextNavigationClick}
          disabled={firstDayCurrentMonth > twoYearsAheadStartOfMonth}
          ssrIcon={ChevronRightIcon}
          small
          iconOnly
        />

        <Text tagName="span" headingSize="xs">
          {weekTitle}
        </Text>
        {weekdays.map((weekday) => (
          <Text key={weekday} tagName="span" headingSize="xs">
            {weekday[0]?.toUpperCase()}
          </Text>
        ))}

        {daysInMonth.map((day, index) => (
          <Day
            key={day.toString()}
            day={day}
            index={index}
            firstDayCurrentMonth={firstDayCurrentMonth}
            selectedRange={selectedRange}
            validRange={memoizedValidRange}
            onChange={onChange}
          />
        ))}
        {onApply !== undefined && (
          <div className={footerStyles}>
            <Text tagName="span" bodySize="s" className={footerPeriodStyles}>
              {selectedRange !== undefined
                ? periodString(
                    selectedRange[0],
                    selectedRange[1],
                    i18n.language,
                  )
                : ""}
            </Text>
            <Button
              type="primary"
              text={t("plan.apply")}
              small
              onClick={onApply}
              className={footerButtonStyles}
              data-analytics="plan/period/date-change"
            />
          </div>
        )}
      </div>
    </PopOver>
  );
};
