import { useNavigate } from "react-router-dom";

import { useBasename } from "../BasenameContext";

export const useBaseNavigate = (): {
  navigate: (value: string) => void;
  getBaseLocationQueryParams: (route: string) => string;
  getBaseLocation: (route: string) => string;
} => {
  const basename = useBasename();
  const navigate = useNavigate();

  const navigateWithBase = (path: string): void => {
    navigate(`${basename}${path}`);
  };

  const getBaseLocationQueryParams = (route: string): string => {
    return `${basename}${route}${window.location.search}`;
  };

  const getBaseLocation = (route: string): string => {
    return `${basename}${route}`;
  };

  return {
    navigate: navigateWithBase,
    getBaseLocationQueryParams,
    getBaseLocation,
  };
};
