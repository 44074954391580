import { useQuery } from "@tanstack/react-query";

import { plantaApi } from "../../api/fetch";
import { Budget } from "./types";
import { periodBudget$ } from "../period";
import { fmtHhMm } from "../dates/transforms";
import { ORG_NODE_BY_ID, orgNodes$ } from "../org/state";
import { getCostCentresFromOrgNode } from "../org/transforms";
import { ClientBusinessNode } from "../org/types";
import { showV6 } from "../experimental/transforms";

interface UseGetBudgetReturn {
  readonly data: ReadonlyArray<Budget>;
  readonly isLoading: boolean;
  readonly refetch: () => void;
}

interface BudgetArgs {
  unitType: string;
  publicUnitId: string;
  orgId: string;
  startDate: string;
  endDate: string;
  keepPreviousData?: boolean;
}

export const useGetBudget = ({
  unitType,
  publicUnitId,
  orgId,
  startDate,
  endDate,
  keepPreviousData,
}: BudgetArgs): UseGetBudgetReturn => {
  const selectedOrg = ORG_NODE_BY_ID.get(orgId) as ClientBusinessNode;
  const orgNodes = orgNodes$.value;

  const { data, refetch, isLoading } = useQuery<ReadonlyArray<Budget>>({
    queryKey: [`${unitType}-${publicUnitId}-${startDate}-${endDate}`],
    queryFn: async ({ signal }) => {
      if (keepPreviousData === true) {
        return [];
      }
      const budget = await plantaApi.jsonInJsonOut<ReadonlyArray<Budget>>(
        `/budgets/${unitType}/${publicUnitId}?startDate=${startDate}&endDate=${endDate}`,
        { method: "GET", signal },
      );
      return budget;
    },
    enabled: showV6,
  });

  if (data === undefined) {
    return {
      data: [],
      isLoading: false,
      refetch,
    };
  }

  //Get all CCs in the selected org
  const costCentres = getCostCentresFromOrgNode(orgNodes, selectedOrg);

  /* 
  The CostCentre Ids we get from budgeting and extIds for CostCentres are formatted like this: CR71013340 
  Our org endpoint returns it in this format: "CC4030",
  so we have to extract the last 4 digits from the budgeting CCs and compare it to the org CCs.
   */
  const { totalMinutes, filteredData } = data.reduce(
    (acc, item) => {
      if (
        item.costCentreCode.length > 4 &&
        costCentres.has(`CC${item.costCentreCode.substring(6, 10)}`)
      ) {
        acc.filteredData.push(item);
        acc.totalMinutes += item.hours * 60;
      }

      return acc;
    },
    { totalMinutes: 0, filteredData: [] as Budget[] },
  );

  const formattedTotal = fmtHhMm(totalMinutes);
  periodBudget$.value = formattedTotal.toLocaleString();

  return {
    data: filteredData,
    isLoading,
    refetch,
  };
};
