import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n
  .use(initReactI18next)
  .init({
    fallbackLng: false,
    load: "currentOnly",
    returnObjects: true,
    interpolation: {
      escapeValue: false,
    },
    ns: ["common"],
    defaultNS: "common",
  })
  .catch(console.error);

export { i18n };
