import { headcount$, isLoadingPublishedPlan$ } from "./state";
import { HeadCount, PlanView, PlanViewData } from "../../types/api";
import { plantaApi } from "../../api/fetch";
import { period$, periodHeadCount$, periodPlan$ } from "../period";
import { View } from "../../types/client";
import { getQueryString } from "../../utils/queryParams";
import { sumDayHeadcountsByWeek } from "./transforms";

interface GetHeadcount {
  readonly view: View;
  readonly orgNodeId: string;
  readonly period: Readonly<[string, string]>;
  readonly signal?: AbortSignal;
  readonly level?: string;
}

export const getHeadcountRequest = async ({
  period,
  orgNodeId,
  view,
  signal,
  level,
}: GetHeadcount): Promise<PlanViewData> => {
  const apiView =
    view === View.Day
      ? PlanView.DayQuarters
      : view === View.FcWeek || view === View.Period
        ? PlanView.Day
        : PlanView.Day;

  const params = getQueryString({
    level,
  });

  const apiRes = await plantaApi.jsonInJsonOut<PlanViewData>(
    `/headcount/plan/${apiView}/${orgNodeId}?period=${period.toString()}&${params}`,
    {
      method: "GET",
      signal,
    },
  );

  if (view === View.Week) {
    periodPlan$.value = apiRes.data.map((d) => ({
      ...d,
      dayQuarters: d.dayQuarters.map((v) => (v === -1 ? 0 : v)),
    }));
  }

  if (view === View.Period) {
    const groupedByWeek: HeadCount[] = apiRes.data.map((d) => ({
      ...d,
      dayQuarters: sumDayHeadcountsByWeek(
        d.dayQuarters,
        period$.value[0],
        period$.value[1],
      ),
    }));

    periodHeadCount$.value = groupedByWeek;
    return { data: groupedByWeek };
  }

  return apiRes;
};

export const getHeadcount = async (
  args: GetHeadcount,
): Promise<ReadonlyArray<HeadCount> | undefined> => {
  try {
    isLoadingPublishedPlan$.value = true;
    const { data } = await getHeadcountRequest(args);
    headcount$.value = [...data];
    return data;
  } finally {
    isLoadingPublishedPlan$.value = false;
  }
};

interface PutHeadcount {
  readonly orgId: string;
  readonly day: string;
  readonly dayQuarters?: ReadonlyArray<number>;
  readonly planningMethod?: number;
}

export const putHeadcount = async ({
  orgId,
  day,
  dayQuarters,
  planningMethod,
}: PutHeadcount): Promise<void> => {
  const body = {
    dayQuarters: dayQuarters,
    planningMethod: planningMethod,
  };

  if (dayQuarters !== undefined) {
    body.dayQuarters = body.dayQuarters?.map((num) => (num === -1 ? 0 : num));
  }
  if (planningMethod !== undefined) {
    body.planningMethod = planningMethod;
  }

  return plantaApi.jsonInVoidOut(`/headcount/${orgId}/${day}`, {
    method: "PUT",
    body: JSON.stringify(body),
  });
};

export const setHeadcountInServer = async ({
  day,
  values,
}: {
  day: string;
  values: { id: string; value: number[] }[];
}): Promise<void> => {
  // Update data in Server

  const promises = Array.from(values).map(({ id, value }) =>
    putHeadcount({ orgId: id, day, dayQuarters: value }),
  );
  await Promise.all(promises);
};
