import { FC, useState, Fragment, memo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { setDefaultOptions } from "date-fns";

import Button from "@ingka/button";
import SSRIcon from "@ingka/ssr-icon";
import ChevronDownIcon from "@ingka/ssr-icon/paths/chevron-down-small";
import ChevronRightIcon from "@ingka/ssr-icon/paths/chevron-right-small";
import ListView, { ListViewItem } from "@ingka/list-view";
import { PopOver, useBoolean } from "@pdpp/lib-react";
import { getWeekdayRegionOffs } from "@pdpp/lib-planta";

import { css, cx } from "../__generated-styled-system/css";
import { Intralink } from "./Intralink";
import { ORG_NODE_BY_ID, orgId$ } from "../features/org/state";
import { ClientBusinessNode } from "../features/org/types";
import {
  getAncestors,
  getChildrenWithoutActivities,
  getSelectBreadCrumbs,
  getSiblings,
} from "../features/org/transforms";
import { weekStartsOn$ } from "../features/dates/state";

const containerStyles = css({
  boxShadow: "shadowSizeDefault",
  backgroundColor: "colourStaticWhite",
  width: "640px",
  height: "500px",
  "@media (width < 700px)": {
    width: "380px",
    height: "240px",
  },
});

const headerStyles = css({
  padding: "space100!",
  display: "flex",
  alignItems: "center",
  gap: "space25",
  borderStyle: "solid!",
  borderBottomColor: "colourStaticGrey!",
  borderBottomWidth: "1px!",
  height: "57px",
});

const contentStyles = css({
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  height: "calc(100% - 57px)",
});

const selectorWrapperStyles = css({
  display: "flex",
  flexDirection: "column",
  gap: "space50",
});

const activeItemStyles = css({
  backgroundColor: "colourStaticLightGrey",
});

const breadcrumbsWrapper = css({
  display: "flex",
  alignItems: "center",
});

const subListStyles = css({
  borderStyle: "solid!",
  borderLeftColor: "colourStaticGrey!",
  borderLeftWidth: "1px!",
});

const listViewStyles = css({
  overflowY: "auto",
});

const buttonStyles = css({
  alignSelf: "flex-start",
  margin: "-0.3rem 0 0 -1.4rem!",
  fontSize: "1rem!",
});

const intraLinkStyles = css({
  fontSize: "0.75rem!",
});

function getName(node: ClientBusinessNode, locale: string): string {
  if (node.type === "country") {
    return (
      new Intl.DisplayNames(locale, {
        type: "region",
      })
        //API responses is 3-letter coded only when China, CHN.
        .of(node.name === "CHN" ? "CN" : node.name) ?? node.name
    );
  }

  return node.name;
}

interface OrgItemProps {
  readonly node: ClientBusinessNode;
  readonly childrenWithoutActivities: ReadonlyArray<ClientBusinessNode>;
}

const OrgItem: FC<OrgItemProps> = memo(function OrgItem$({
  node,
  childrenWithoutActivities,
}) {
  const onClick =
    node.type === "activity"
      ? undefined
      : () => {
          orgId$.value = node.id;
        };

  const name = node.name;
  const isActive = node.id === orgId$.value;
  const hasChildren = childrenWithoutActivities.length > 0;

  return (
    <ListViewItem
      id={node.id}
      className={isActive ? activeItemStyles : undefined}
      title={name}
      control="navigational"
      controlIcon={isActive && hasChildren ? "chevronRightSmall" : ""}
      inset={true}
      emphasised={false}
      size="small"
      showDivider={false}
      onClick={onClick}
    />
  );
});

interface LinkItemProps {
  readonly node: ClientBusinessNode;
  readonly locale: string;
}

const LinkItem: FC<LinkItemProps> = memo(function LinkItem$({ node, locale }) {
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  params.set("orgNode", node.id);

  if (node.type === "country") {
    weekStartsOn$.value = getWeekdayRegionOffs(node.id);
    setDefaultOptions({ weekStartsOn: weekStartsOn$.value });
  }

  return (
    <Fragment>
      <Intralink
        className={intraLinkStyles}
        text={getName(node, locale)}
        url={`${location.pathname}?${params.toString()}`}
        onClick={() => {
          orgId$.value = node.id;
        }}
        disabled={node.type === "country"}
      />
      <SSRIcon paths={ChevronRightIcon} />
    </Fragment>
  );
});

interface OrganisationSelectProps {
  readonly nodes: ReadonlyArray<ClientBusinessNode>;
  readonly orgId: string | undefined;
}
export const OrganisationSelect: FC<OrganisationSelectProps> = memo(
  function OrganisationSelect$({ nodes, orgId }) {
    const [, i18next] = useTranslation();
    const [isPopoverShown, , hidePopover, togglePopover] = useBoolean();

    const breadCrumbs = getSelectBreadCrumbs(nodes, orgId);
    const selected = ORG_NODE_BY_ID.get(orgId ?? "");
    const ancestors = getAncestors(nodes, orgId);
    const siblings = getSiblings(nodes, orgId);
    const childrenWithoutActivities = getChildrenWithoutActivities(
      nodes,
      orgId,
    );

    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

    return (
      <div>
        <div className={selectorWrapperStyles}>
          <div className={breadcrumbsWrapper}>
            {breadCrumbs.map((n) => (
              <LinkItem key={n.id} node={n} locale={i18next.language} />
            ))}
          </div>
          <Button
            ref={setAnchorEl}
            className={buttonStyles}
            text={selected?.name}
            type="tertiary"
            small={true}
            iconPosition="trailing"
            ssrIcon={ChevronDownIcon}
            onClick={togglePopover}
            data-analytics="plan/organisation-select"
          />
          {isPopoverShown && (
            <PopOver
              clickAwayHandler={hidePopover}
              anchorEl={anchorEl}
              horizontalAlignment="left"
              horizontalPosition="left"
              verticalPosition="bottom"
              verticalAlignment="top"
              onScroll={hidePopover}
            >
              <div className={containerStyles}>
                <div className={headerStyles}>
                  {ancestors.map((n) => (
                    <LinkItem key={n.id} node={n} locale={i18next.language} />
                  ))}
                </div>
                <div className={contentStyles}>
                  <ListView
                    className={listViewStyles}
                    id="org-select-1"
                    size="small"
                  >
                    {siblings.map((n) => (
                      <OrgItem
                        key={n.id}
                        node={n}
                        childrenWithoutActivities={childrenWithoutActivities}
                        data-analytics={`plan/organisation-select/${n.name}`}
                      />
                    ))}
                  </ListView>
                  <ListView
                    className={cx(subListStyles, listViewStyles)}
                    id="org-select-2"
                    size="small"
                  >
                    {selected !== undefined &&
                      childrenWithoutActivities.map((n) => (
                        <OrgItem
                          key={n.id}
                          node={n}
                          childrenWithoutActivities={childrenWithoutActivities}
                          data-analytics={`plan/organisation-select/${n.name}`}
                        />
                      ))}
                  </ListView>
                </div>
              </div>
            </PopOver>
          )}
        </div>
      </div>
    );
  },
);
