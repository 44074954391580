import { FC } from "react";
import { useTranslation } from "react-i18next";

import ListView from "@ingka/list-view";
import Text from "@ingka/text";

import { css } from "../__generated-styled-system/css";
import { LanguageItem } from "./LanguageItem";
import { SUPPORTED_LANGUAGES } from "../language/language";

const listWrapperStyles = css({
  marginTop: "space200!",
});

const listContainerStyles = css({
  width: "100%!",
  display: "flex",
});

const listViewStyles = css({
  width: "100%!",
});

export const Language: FC = () => {
  const [t] = useTranslation();

  return (
    <div>
      <Text headingSize="m">{t("language.choosePreferredLanguage")}</Text>
      <div className={listWrapperStyles}>
        <Text headingSize="s">{t("language.availableLanguage")}</Text>
        <div className={listContainerStyles}>
          <ListView
            id="header-languages"
            className={listViewStyles}
            size="small"
            showDivider={false}
          >
            {SUPPORTED_LANGUAGES.map(({ key, value }) => (
              <LanguageItem key={key} id={key} label={value} />
            ))}
          </ListView>
        </div>
      </div>
    </div>
  );
};
