import { FC, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { changeLanguage } from "i18next";

import { getOppCookie, setOppCookie } from "@pdpp/lib-react";
import { ListViewItem } from "@ingka/list-view";

import { SupportedLanguage, addLanguageBundles } from "../language/language";

interface HeaderLanguageItemProps {
  readonly id: SupportedLanguage;
  readonly label: string;
}

export const LanguageItem: FC<HeaderLanguageItemProps> = ({ id, label }) => {
  const oppCookie = getOppCookie();
  const { i18n } = useTranslation();
  const controlProps = useMemo(
    () => ({ checked: i18n.language === id, value: id }),
    [id, i18n.language],
  );

  const handleOnChange = useCallback(() => {
    setOppCookie({ ...oppCookie, lang: id });
    changeLanguage(id);

    return addLanguageBundles(id);
  }, [oppCookie, id]);

  return (
    <ListViewItem
      id={id}
      title={label}
      control="radiobutton"
      controlProps={controlProps}
      onChange={handleOnChange}
      showDivider={false}
    />
  );
};
