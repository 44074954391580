export function getQueryParam(key: string): string | null {
  return new URLSearchParams(window.location.search).get(key);
}

export function setQueryParam(key: string, value: string): void {
  const params = new URLSearchParams(window.location.search);
  params.set(key, value);
  window.history.replaceState(
    undefined,
    "",
    `${window.location.pathname}?${params.toString()}`,
  );
}

interface GetQueryStringProps {
  [key: string]: string | undefined;
}
export const getQueryString = (data: GetQueryStringProps): string => {
  let queryString = "";

  for (const key in data) {
    if (data[key as string] !== undefined) {
      queryString += `${key}=${encodeURIComponent(data[key] as string)}&`;
    }
  }

  queryString = queryString.slice(0, -1);

  return queryString;
};
