import { useQuery } from "@tanstack/react-query";

import { Organisation } from "@pdpp/lib-planta";

import { plantaApi } from "../../api/fetch";
import { getNormalisedOrgNodes } from "./transforms";
import { orgNodes$ } from "./state";
import { BadRequest } from "../../api/types.mjs";

export const useGetOrgStructure = (
  signal?: AbortSignal,
  enabled = true,
): {
  data: Organisation | null;
  refetch: () => void;
  loading: boolean;
} => {
  const {
    data,
    refetch,
    isLoading: loading,
  } = useQuery<Organisation | null>({
    initialData: null,
    queryKey: [`org-structure`],
    queryFn: async () => {
      const res = await plantaApi.jsonInJsonOut<Organisation>(
        `/headcount/organisation-structure`,
        { signal },
      );

      if (res.nodes !== null) {
        orgNodes$.value = getNormalisedOrgNodes(res.nodes);
      }
      return res;
    },
    enabled,
  });

  return {
    data,
    refetch,
    loading,
  };
};

interface CreatedActivity {
  data: string;
}

type CrateActivityResponse = CreatedActivity | BadRequest;

export const putActivity = async (
  department: string,
  name: string,
  description: string,
): Promise<CrateActivityResponse> => {
  try {
    const response = await plantaApi.jsonInJsonOut<CrateActivityResponse>(
      `/activity`,
      {
        method: "PUT",
        body: JSON.stringify({
          activity: name,
          departmentId: department,
          description,
        }),
      },
    );
    return response;
  } catch (error) {
    // @ts-expect-error error is a Response with known schema
    return { error: (await error.response.json()).error };
  }
};
