import { signal, effect } from "@preact-signals/safe-react";

import {
  getShareableSetting,
  setShareableSetting,
} from "../../utils/shareableSetting";
import type { ClientBusinessNode } from "./types";
import { getAncestors } from "./transforms";

const KEY = "orgNode";

export const orgId$ = signal(getShareableSetting(KEY) ?? undefined);
export const orgNodes$ = signal<ReadonlyArray<ClientBusinessNode>>([]);
export const selectedUnit$ = signal<ClientBusinessNode | undefined>(undefined);

type OrgNodeById = Map<string, ClientBusinessNode>;

export const ORG_NODE_BY_ID: OrgNodeById = new Map();

effect(() => {
  const value = orgId$.value;
  if (value !== undefined) {
    setShareableSetting(KEY, value);
  }
});

// Populates orgNodes Map
effect(() => {
  const orgNodes = orgNodes$.value;

  for (const node of orgNodes) {
    ORG_NODE_BY_ID.set(node.id, node);
  }
});

effect(() => {
  const orgNodes = orgNodes$.value;
  const orgIg = orgId$.value;

  const ancestors = getAncestors(orgNodes, orgIg);
  const selectedUnit = ancestors.find((ancestor) => ancestor.type === "unit");

  if (selectedUnit) {
    selectedUnit$.value = selectedUnit;
  }
});
